import React from 'react';
import { ReactNode } from 'react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import { RESULT_CONTENT_LENGTH } from '../../../shared/Constants';

export const highlightText = (
  text: string,
  highlights: Array<[number, number]>,
  queryTokens: Array<string>,
  content_is_formated: Boolean,
): Array<string | ReactNode> => {
  // if the content is already formatted by the backend, just make the matches bold
  if (content_is_formated) {
    return [
      <TextSpan className="showCollapsed" key={0}>
        {makeMatchesBold(queryTokens, text)}
      </TextSpan>,
    ];
  }

  // if the document is not formatted and there is no highlighted sentences in the document
  // simply truncate the text in RESULT_CONTENT_LENGTH characters and if you do
  // add an ellipsis at the end of the text
  if (!highlights || highlights.length === 0) {
    const formatted_text =
      text.length > RESULT_CONTENT_LENGTH ? text.slice(0, RESULT_CONTENT_LENGTH) + '...' : text;
    return [
      <TextSpan className="showCollapsed" key={0}>
        {makeMatchesBold(queryTokens, formatted_text)}
      </TextSpan>,
    ];
  }

  // if the document is not already formatted and it has highlights
  // the snippet should be in the form of
  //...<highlighted sentence 1>...<highlighted sentence 2>...

  let highlighted: Array<string | ReactNode> = [];

  //the highlights are a list of integers pairs, each containing the
  //first and last character position of the highlighted sentence.
  highlights.forEach((highlight, i) => {
    const [start, end] = highlight;

    // for each highlighted sentence add a leading ellipsis
    highlighted.push(
      <Ellipsis key={`${i}-ellipsis-1`} className="showCollapsed">
        ...
      </Ellipsis>,
    );
    // gets the highlighted substring from the document text
    highlighted.push(
      <Highlight className="highlight" key={`${i}-2`}>
        {makeMatchesBold(queryTokens, text.substr(start, end - start + 1))}
      </Highlight>,
    );
  });
  //add the last ellipsis to the text
  highlighted.push(
    <Ellipsis key={`last-ellipsis-1`} className="showCollapsed">
      ...
    </Ellipsis>,
  );

  return highlighted;
};

export const makeMatchesBold = (queryTokens: Array<string>, text: string): ReactNode => {
  return (
    <Highlighter
      searchWords={queryTokens}
      textToHighlight={text}
      highlightTag={Match}
      highlightClassName="match"
    />
  );
};

// adjust highlights based on difference
export const adjustHighlights = (
  highlights: Array<[number, number]>,
  adjustment: number,
): Array<[number, number]> => {
  if (!highlights) {
    return [];
  }

  return highlights.map((highlight) => [highlight[0] + adjustment, highlight[1] + adjustment]);
};

const TextSpan = styled.span`
  line-height: 1.4;
  transition: all 0.3s;
`;

const Ellipsis = styled(TextSpan)`
  letter-spacing: 1px;
  margin: 0 2px;
`;

const Highlight = styled(TextSpan)`
  position: relative;
  font-weight: 400;

  & > span > .match {
    font-weight: 800;
  }
`;

const Match = styled(TextSpan)`
  position: relative;
  font-weight: 800;
`;
