import React, { useContext } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import { QUEST_COLORS } from '../QuestTheme';
import QuestSearchType, { IIndexSelected } from './QuestSearchType';
import QuestSearchBar from './QuestSearchBar';
import { useHistory } from 'react-router';
import QuestSearchResultContext, {
  IQuestSearchResultProviderProps,
} from '../../QuestSearchResultContext';
import QuestIndexContext, { QuestIndexProviderProps } from '../../QuestIndexContext';
import { QUEST_RESULT_ROUTE } from '../../../../../shared/Constants';
import { isBrowser } from 'react-device-detect';

const QuestSearch = ({ indexSelected }: IIndexSelected) => {
  const { getTerm } = useContext(QuestSearchResultContext) as IQuestSearchResultProviderProps;
  const { getSelectedIndex } = useContext(QuestIndexContext) as QuestIndexProviderProps;

  const history = useHistory();
  const redirectToResultList = () => {
    if (getTerm()) {
      history.push(
        `${QUEST_RESULT_ROUTE}?query=${encodeURIComponent(getTerm())}&index=${encodeURIComponent(
          getSelectedIndex() || '',
        )}`,
      );
    }
  };

  return (
    <Grid container direction="row">
      <Grid item xs={12} md={2}>
        <QuestSearchType indexSelected={indexSelected} />
      </Grid>
      <Grid item xs={12} md={9}>
        <QuestSearchBar />
      </Grid>
      {isBrowser ? (
        <Grid item style={{ display: 'flex' }}>
          <QuestSearchButton disableRipple size="small" onClick={redirectToResultList}>
            <Search />
          </QuestSearchButton>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <QuestSearchButtonMob disableRipple onClick={redirectToResultList}>
            <Search />
          </QuestSearchButtonMob>
        </Grid>
      )}
    </Grid>
  );
};

const QuestSearchButton = withStyles({
  root: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    textTransform: 'lowercase',
    width: 60,
    backgroundColor: QUEST_COLORS.bgAndTitles,
    color: 'white',
    '&:hover': {
      backgroundColor: QUEST_COLORS.bgAndTitles,
    },
  },
})(IconButton);

const QuestSearchButtonMob = withStyles({
  root: {
    width: '100%',
    border: 1,
    borderRadius: 10,
    marginTop: 10,
  },
})(QuestSearchButton);

export default QuestSearch;
