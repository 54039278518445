import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  API_BASE,
  GLOBAL_TOAST_OPTIONS,
  QUEST_RESULT_ROUTE,
} from '../../../../../shared/Constants';
import AuthContext, { AuthProviderProps } from '../../../../../shared/context/AuthContext';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Input, TextField, withStyles } from '@material-ui/core';
import QuestSearchResultContext, {
  IQuestSearchResultProviderProps,
} from '../../QuestSearchResultContext';
import { useHistory } from 'react-router';
import QuestIndexContext, { QuestIndexProviderProps } from '../../QuestIndexContext';

const QuestSearchBar = () => {
  const history = useHistory();

  const { user, getTokenAuthHeader } = useContext(AuthContext) as AuthProviderProps;
  const { setTerm, getTerm, openDropdownResult, setOpenDropdownResult, setEmail, setIsChecked } =
    useContext(QuestSearchResultContext) as IQuestSearchResultProviderProps;
  const { getSelectedIndex } = useContext(QuestIndexContext) as QuestIndexProviderProps;
  const [suggestions, setSuggestions] = useState([]);
  const loading = openDropdownResult && suggestions?.length > 3;
  const [value, setValue] = useState<string>('');
  const userEmailRef = useRef<HTMLInputElement>(null);
  const userCheckboxRef = useRef<HTMLInputElement>(null);

  const loadSuggestions = async (inputVal: string) => {
    if (!inputVal.trim()) {
      setOpenDropdownResult(false);
      return;
    }

    let headers = {};
    if (user.token) {
      headers = await getTokenAuthHeader();
    }

    const response = await fetch(
      `${API_BASE}/suggest?query=${encodeURIComponent(inputVal)}&user_language=${
        navigator.language
      }`,
      {
        method: 'GET',
        headers,
      },
    );

    if (response.status === 200) {
      const data = await response.json();
      const suggestions = data['suggestions'];
      setSuggestions(suggestions);
    } else {
      toast.error(
        'Ocorreu um erro. Por favor, tente novamente em alguns minutos.',
        GLOBAL_TOAST_OPTIONS,
      );
    }
  };

  const setHoneyPotFields = () => {
    // Set the spam honeypot fields to prevent bots from submitting the form
    // if the user is a human, these fields should be empty
    setIsChecked(userCheckboxRef.current?.checked ? true : false);
    setEmail(userEmailRef.current?.value ? userEmailRef.current?.value : '');
  };

  const loadSuggestionOnFocus = () => {
    loadSuggestions(getTerm());
  };

  useEffect(() => {
    if (!openDropdownResult) {
      setSuggestions([]);
    }
  }, [openDropdownResult]);

  return (
    <>
      <Autocomplete
        id="quest-search-bar"
        loadingText="Carregando..."
        value={value}
        freeSolo
        filterOptions={(options, state) => options}
        onChange={(event: any, newValue: string | null) => {
          setHoneyPotFields();
          if (newValue) {
            setValue(newValue);
            history.push(
              `${QUEST_RESULT_ROUTE}?query=${encodeURIComponent(
                newValue,
              )}&index=${encodeURIComponent(getSelectedIndex() || '')}`,
            );
          }
        }}
        loading={loading}
        open={openDropdownResult}
        onOpen={() => {
          setOpenDropdownResult(true);
        }}
        onClose={() => {
          setOpenDropdownResult(false);
        }}
        autoComplete
        onFocus={loadSuggestionOnFocus}
        inputValue={getTerm()}
        options={suggestions}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <SearchBarForms component={'form'} id="forms">
            <InputSearch
              {...params}
              variant="outlined"
              onKeyDown={(e) => {
                if (e.key.toLowerCase() === 'enter') {
                  if (getTerm()) {
                    setHoneyPotFields();
                    history.push(
                      `${QUEST_RESULT_ROUTE}?query=${encodeURIComponent(
                        getTerm(),
                      )}&index=${encodeURIComponent(getSelectedIndex() || '')}`,
                    );
                  }
                }
              }}
            />
            <HoneyPotInput type="checkbox" id="checkbox_quest" inputRef={userCheckboxRef} />
            <HoneyPotInput type="email" id="email_quest" inputRef={userEmailRef} />
          </SearchBarForms>
        )}
        onInputChange={(event, newInputValue) => {
          setTerm(newInputValue);
          loadSuggestions(newInputValue);
        }}
      />
    </>
  );
};

const InputSearch = withStyles({
  root: {
    backgroundColor: 'white',
    '& .MuiAutocomplete-inputRoot': {
      height: 47,
      padding: '2.5px',
      borderRadius: 0,
    },
  },
})(TextField);

const HoneyPotInput = withStyles({
  root: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    height: 0,
    width: 0,
  },
})(Input);

const SearchBarForms = withStyles({
  root: {
    display: 'flex',
    width: '100%',
    background: 'red',
  },
})(Box);

export default QuestSearchBar;
