import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import QuestSearch from '../shared/QuestSearch/QuestSearch';

import { Link, useLocation } from 'react-router-dom';

import { QuestTextLabel, QUEST_COLORS } from '../shared/QuestTheme';
import QuestNormalLogo from '../../../../img/quest-w.png';
import WebFont from 'webfontloader';
import QuestSearchResultContext, {
  IQuestSearchResultProviderProps,
  ISearchResult,
  ISearchResultReranker,
} from '../QuestSearchResultContext';
import { API_BASE, GLOBAL_TOAST_OPTIONS, SEARCH_ENDPOINT } from '../../../../shared/Constants';
import AuthContext, { AuthProviderProps } from '../../../../shared/context/AuthContext';
import QuestIndexContext, { QuestIndexProviderProps } from '../QuestIndexContext';

import styles from './QuestSearchResult.module.css';
import { Helmet } from 'react-helmet';
import { Search } from 'react-feather';
import { toast } from 'react-toastify';
import { isBrowser, isMobile } from 'react-device-detect';
import { tokenize } from '../../../../shared/Util';
import { highlightText } from '../../../dataset/generic/highlighter';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

interface IQuestSearchResultProps {
  homeQuestUrl: string;
}

const QuestSearchResult = ({ homeQuestUrl }: IQuestSearchResultProps) => {
  const { user, getTokenAuthHeader, getResponse } = useContext(AuthContext) as AuthProviderProps;
  const { setSelectedIndex } = useContext(QuestIndexContext) as QuestIndexProviderProps;
  const { setTerm, getTerm, setSearchResult, getSearchResult, email, isChecked } = useContext(
    QuestSearchResultContext,
  ) as IQuestSearchResultProviderProps;

  const [loading, setLoading] = useState(false);
  const [ellapsedTime, setEllapsedTime] = useState<number>(0);
  const [popularQueries, setPopularQueries] = useState([]);
  const [recommendations, setRecommendations] = useState<ISearchResult | undefined>();

  const urlParams = new URLSearchParams(useLocation().search);
  const query = urlParams.get('query') || '';
  const index = urlParams.get('index') || '';
  const queryTokens = tokenize(query);

  useEffect(() => {
    setTerm(decodeURIComponent(query));
    setSelectedIndex(decodeURIComponent(index));
  }, [index, query, setSelectedIndex, setTerm]);

  useEffect(() => {
    const loadSearchResult = async () => {
      const startTimestamp = performance.now();

      setLoading(true);
      let headers: any = {};
      headers = await getTokenAuthHeader();
      headers.email = email;
      headers.isChecked = isChecked;
      let response = await getResponse(
        `${API_BASE}${SEARCH_ENDPOINT}?index=${encodeURIComponent(
          index,
        )}&query=${encodeURIComponent(query.toLowerCase())}`,
        'GET',
        headers,
      );

      if (response.status === 200) {
        const endTimestamp = performance.now();
        setEllapsedTime(Math.round((endTimestamp - startTimestamp) / 10) / 100);

        const items = await response.json();
        setSearchResult(items);
        setLoading(false);
      } else if (response.status !== 403) {
        toast.error(
          'Ocorreu um erro. Por favor, tente novamente em alguns minutos.',
          GLOBAL_TOAST_OPTIONS,
        );
      }
    };

    loadSearchResult();
  }, [email, getResponse, getTokenAuthHeader, index, isChecked, query, setSearchResult]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins'],
      },
    });
  }, []);

  useEffect(() => {
    const loadPopularQueries = async () => {
      let headers = {};

      if (user.token) {
        headers = await getTokenAuthHeader();
      }

      const response = await getResponse(`${API_BASE}/popular_queries`, 'GET', headers);

      if (response.status === 200) {
        const data = await response.json();
        setPopularQueries(data['queries']);
      } else {
        toast.error(
          'Ocorreu um erro. Por favor, tente novamente em alguns minutos.',
          GLOBAL_TOAST_OPTIONS,
        );
      }
    };

    loadPopularQueries();
  }, [getResponse, getTokenAuthHeader, user.token]);

  useEffect(() => {
    const loadSearchRecommendations = async () => {
      let headers: any = {};
      headers = await getTokenAuthHeader();
      let latitude = localStorage.getItem('latitude') || undefined;
      let longitude = localStorage.getItem('longitude') || undefined;
      let response = await getResponse(
        `${API_BASE}${SEARCH_ENDPOINT}?index=sebrae_recomendacoes&query=${encodeURIComponent(
          query.toLowerCase(),
        )}&return_raw=True&max_docs_to_return=3${latitude ? `&latitude=${latitude}` : ''}${
          longitude ? `&longitude=${longitude}` : ''
        }`,
        'GET',
        headers,
      );

      if (response.status === 200) {
        const items: ISearchResult = await response.json();
        setRecommendations(items);
      } else if (response.status !== 403) {
        toast.error(
          'Ocorreu um erro. Por favor, tente novamente em alguns minutos.',
          GLOBAL_TOAST_OPTIONS,
        );
      }
    };
    loadSearchRecommendations();
  }, [getResponse, getTokenAuthHeader, query]);

  const handlerPopularQueries = (query: string) => {
    setTerm(query);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      document.getElementById('quest-search-bar')?.focus();
    }, 1000);
  };

  const handleOnClickResult = (
    event: React.MouseEvent<HTMLAnchorElement>,
    result: ISearchResultReranker,
  ) => {
    // Prevent default behavior when clicking a link
    event.preventDefault();
    const anchor = event.currentTarget as HTMLAnchorElement;
    const hasSBRTSource = result.source_database && result.source_database === 'SBRT';

    const downloadFile = async () => {
      const downloadingToastId = toast.info(
        `Downloading "${result.title}"...`,
        GLOBAL_TOAST_OPTIONS,
      );

      const response = await fetch(result.url, {
        headers: await getTokenAuthHeader(),
      });

      const file: Blob = await response.blob();
      var fileURL = URL.createObjectURL(file);

      // Open new windows and show PDF
      window.open(fileURL, '_blank');

      toast.dismiss(downloadingToastId);
      toast.success(
        <>
          Downloaded "
          <a href={fileURL} target="_blank" rel="noopener noreferrer">{`${result.title}`}</a>"
        </>,
        GLOBAL_TOAST_OPTIONS,
      );
    };

    if (hasSBRTSource) {
      downloadFile();
    } else {
      const url = anchor.href;
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <Helmet>
        <title>Quest - {getTerm()}</title>
      </Helmet>
      <Grid container style={{ backgroundColor: 'white' }}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            bgcolor={QUEST_COLORS.bgAndTitles}
          >
            <Container maxWidth="lg">
              <Link to={homeQuestUrl}>
                <img
                  src={QuestNormalLogo}
                  alt="Logo do Quest"
                  style={{ width: '100px', padding: '10px 0' }}
                />
              </Link>
            </Container>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box marginTop="5vh" marginBottom="5vh">
            <Container maxWidth="lg">
              <QuestSearch indexSelected={index} />
            </Container>
          </Box>
        </Grid>
        <Container maxWidth="lg">
          {loading ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              {!getSearchResult()?.response_reranker.length ? (
                <>
                  <QuestTextLabel variant="subtitle2">
                    Sua pesquisa não encontrou pesquisas relevantes
                  </QuestTextLabel>
                  <QuestTextLabel variant="h1" style={{ margin: '3vh 0' }}>
                    Pesquisas relacionadas:
                  </QuestTextLabel>
                  <Grid item direction="row" xs={12} md={6}>
                    <List>
                      {popularQueries.map((query: string) => (
                        <ListItem key={query} disableGutters>
                          <ListItemText
                            primary={<QuestTextLabel variant="body1">{query}</QuestTextLabel>}
                          />
                          <ListItemSecondaryAction>
                            <IconButton disableRipple onClick={() => handlerPopularQueries(query)}>
                              <Search
                                style={{
                                  color: QUEST_COLORS.bgAndTitles,
                                  width: '0.9rem',
                                  height: '0.9rem',
                                }}
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container direction="row" item xs={12}>
                    <Grid item xs={12} md={8}>
                      <QuestTextLabel>
                        Resultados encontrados: {getSearchResult()?.response_reranker.length} (
                        {ellapsedTime}s)
                      </QuestTextLabel>
                    </Grid>
                    {isBrowser &&
                    recommendations &&
                    recommendations.response_reranker.length > 0 ? (
                      <Grid className={styles.recommendations} item xs={12} md={4}>
                        <Box paddingLeft={2}>
                          <QuestTextLabel variant="subtitle1">
                            Recomendado pelo Sebrae
                          </QuestTextLabel>
                        </Box>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container direction="row" item xs={12}>
                    <Grid item xs={12} md={8}>
                      <CardPrincipal
                        result={getSearchResult()?.response_reranker[0]}
                        onClickFunction={handleOnClickResult}
                        queryTokens={queryTokens}
                      />
                      <Divider style={{ marginBottom: 30 }} />
                      {isMobile &&
                      recommendations &&
                      recommendations.response_reranker.length > 0 ? (
                        <Box>
                          <QuestTextLabel
                            variant="subtitle1"
                            style={{ marginBottom: 15, fontWeight: 'normal' }}
                          >
                            Recomendado pelo Sebrae
                          </QuestTextLabel>
                          <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            slidesPerGroup={1}
                            modules={[Pagination]}
                            pagination={true}
                            className="slide-recommendations"
                          >
                            {recommendations?.response_reranker.map(
                              (response: ISearchResultReranker) => {
                                return (
                                  <SwiperSlide key={response.id}>
                                    <RecommendationCard
                                      response={response}
                                      queryTokens={queryTokens}
                                    />
                                  </SwiperSlide>
                                );
                              },
                            )}
                          </Swiper>
                          <Divider style={{ marginBottom: 15, marginTop: 15 }} />
                        </Box>
                      ) : (
                        ''
                      )}

                      {getSearchResult()
                        ?.response_reranker.slice(1, getSearchResult()?.response_reranker.length)
                        .map((response: ISearchResultReranker) => {
                          return (
                            <QuestCard variant="outlined" key={response.id}>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <QuestTextLabel
                                      variant="subtitle2"
                                      style={{ fontSize: '0.8rem' }}
                                    >
                                      {response.source_url}
                                    </QuestTextLabel>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <a
                                      className={styles.link}
                                      href={response.source_url}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={(event) => handleOnClickResult(event, response)}
                                    >
                                      <QuestCaption variant="subtitle1">
                                        {response.title}
                                      </QuestCaption>
                                    </a>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <QuestTextLabel
                                      variant="body1"
                                      style={{
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        WebkitLineClamp: 3,
                                        textAlign: 'left',
                                      }}
                                    >
                                      {highlightText(
                                        response.paragraphs.join('...'),
                                        response.highlights[0],
                                        queryTokens,
                                        response.content_is_formated,
                                      )}
                                    </QuestTextLabel>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </QuestCard>
                          );
                        })}
                    </Grid>
                    <Grid className={styles.recommendations} item xs={12} md={4}>
                      <Box paddingLeft={2}>
                        {isBrowser &&
                          recommendations?.response_reranker.map(
                            (response: ISearchResultReranker) => {
                              return (
                                <RecommendationCard response={response} queryTokens={queryTokens} />
                              );
                            },
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Container>
      </Grid>
    </>
  );
};

interface CardPrincipalProps {
  result: ISearchResultReranker | undefined;
  onClickFunction:
    | React.MouseEventHandler<HTMLAnchorElement>
    | ((event: React.MouseEvent<HTMLAnchorElement>, result: ISearchResultReranker) => void);
  queryTokens: string[];
}

interface RecommendationCardProps {
  response: ISearchResultReranker;
  queryTokens: string[];
}

const CardPrincipal = ({ result, onClickFunction, queryTokens }: CardPrincipalProps) => {
  const paragraphs = !result ? '' : result!.paragraphs.join('...');

  return (
    <QuestCard variant="outlined" style={{ padding: 1, border: 0 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <QuestTextLabel variant="body1">
              {highlightText(
                paragraphs,
                result!.highlights[0],
                queryTokens,
                result!.content_is_formated,
              )}
            </QuestTextLabel>
          </Grid>
          <Grid item xs={12}>
            <QuestTextLabel variant="subtitle2" style={{ fontSize: '0.8rem' }}>
              {result?.source_url}
            </QuestTextLabel>
          </Grid>
          <Grid item xs={12}>
            <a
              className={styles.link}
              href={result?.source_url}
              target="_blank"
              rel="noreferrer"
              onClick={(event) => onClickFunction(event, result as ISearchResultReranker)}
            >
              <QuestCaption variant="subtitle1">{result?.title}</QuestCaption>
            </a>
          </Grid>
        </Grid>
      </CardContent>
    </QuestCard>
  );
};

const QuestCard = withStyles({
  root: {
    borderRadius: 15,
    backgroundColor: 'transparent',
    marginTop: '20px',
    padding: '20px',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
})(Card);

const QuestCaption = withStyles({
  root: {
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
})(QuestTextLabel);

const RecommendationCard = ({ response, queryTokens }: RecommendationCardProps) => {
  return (
    <Accordion expanded={true} key={response.id}>
      <AccordionSummary>
        <QuestTextLabel variant="subtitle2">
          <a
            href={response.source_url}
            target="_blank"
            rel="noreferrer"
            style={{
              width: '100%',
              textDecoration: 'none',
              color: QUEST_COLORS.dark,
            }}
          >
            {response.source_database}
          </a>
        </QuestTextLabel>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0, paddingBottom: 10 }}>
        <a href={response.source_url} target="_blank" rel="noreferrer" style={{ width: '100%' }}>
          <img src={JSON.parse(response.raw).thumbnail} width="100%" alt={response.title} />
        </a>
      </AccordionDetails>
      <AccordionDetails>
        <QuestTextLabel variant="subtitle2" style={{ fontSize: '0.8rem' }}>
          {response.title}
        </QuestTextLabel>
      </AccordionDetails>
      <AccordionDetails>
        <QuestTextLabel variant="body1">
          {highlightText(
            response.paragraphs.join('...'),
            response.highlights[0],
            queryTokens,
            response.content_is_formated,
          )}
        </QuestTextLabel>
      </AccordionDetails>
      <AccordionActions>
        <a
          className={styles.buttonLink}
          href={response.source_url}
          target="_blank"
          rel="noreferrer"
        >
          Saiba mais
        </a>
      </AccordionActions>
    </Accordion>
  );
};

export default QuestSearchResult;
