import { Accordion, AccordionDetails, AccordionSummary, withStyles } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";
import React from "react";
import { QuestTextLabel, QUEST_COLORS, QUEST_FONT_FAMILY } from "../../shared/QuestTheme";

interface QuestAboutAccordionProps {
    title: string,
    description: string
}

const QuestAboutAccordion = ({ title, description } : QuestAboutAccordionProps) => {

    return (
        <QuestAccordion elevation={0} square={false}>
            <QuestAccordionSummary expandIcon={<ExpandLess htmlColor="white" />}>
                <QuestTextLabel variant="h5">{title}</QuestTextLabel>
            </QuestAccordionSummary>
            <QuestAccordionDetails>
                <QuestTextLabel variant="body1" align='justify' style={{ fontSize: '0.9rem' }}>{description}</QuestTextLabel>
            </QuestAccordionDetails>
        </QuestAccordion>
    )
    
}

const QuestAccordion = withStyles({
    root: {
        backgroundColor: QUEST_COLORS.bgAndTitles,
        fontFamily: QUEST_FONT_FAMILY,
        fontSize: '0.8rem',
        marginBottom: '2vh',
        '& .MuiTypography-root': {
            color: 'white',
            fontWeight: 'normal',
            fontSize: '1.1rem',
        },
        '&$expanded': {
            background: 'transparent',
        },
    },
    expanded: {
        background: 'transparent',
        border: 0
    }
})(Accordion);

const QuestAccordionSummary = withStyles({
    root: {
        backgroundColor: QUEST_COLORS.bgAndTitles,
        border: '1px solid white',
        borderRadius: 15,
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            border: 0,
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
          margin: '12px 0',
        },
    },
    expanded: {
        background: QUEST_COLORS.highlights,
        border: 0,
        transition: 'min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
})(AccordionSummary)

const QuestAccordionDetails = withStyles({
    root: {
        backgroundColor: QUEST_COLORS.bgAndTitles,
        borderRadius: 15,
        '&$expanded': {
            backgroundColor: QUEST_COLORS.bgAndTitles,
        },
    }
})(AccordionDetails);


export default QuestAboutAccordion;