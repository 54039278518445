import { Card, CardContent } from "@material-ui/core";
import React from "react";
import { QuestTextLabel } from "../../shared/QuestTheme";

import styles from './QuestCardFeature.module.css';

interface CardProps {
    icon: string,
    title: string,
    description: string,
}

const QuestCardFeature = ({
    icon,
    title,
    description
}: CardProps) => {
    return (
        <Card className={styles.card}> 
            <CardContent>
                <img src={icon} alt="Logo do Quest" />
                <QuestTextLabel variant="subtitle1" style={{ padding: '2rem 0 3rem 0' }}>{title}</QuestTextLabel>
                <QuestTextLabel variant="body1" align="center" className={styles.cardDescription}> {description}</QuestTextLabel>
            </CardContent>
        </Card>
    );
}

export default QuestCardFeature;