import { Link } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const QuestLink = withStyles({
    root: {
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginRight: '10px',
        fontSize: '0.9rem',
        '&:hover': {
            textDecoration: 'none'
        }
    }
})(Link);
