import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const QUEST_COLORS = {
    bgAndTitles: '#0071B9',
    highlights: '#008BFE',
    dark: '#707070',
    darker: '#4B4B4B',
    light: '#FAFAFA',
    borders: '#AFAFAF'
}

export const QUEST_FONT_FAMILY = 'Poppins'

export const QuestTextLabel = withStyles({
    root: {
        fontFamily: QUEST_FONT_FAMILY,
        fontWeight: 'bold'
    },
    h1: {
        fontSize: '1.5rem',
        marginBottom: '3vh',
        color: QUEST_COLORS.bgAndTitles
    },
    subtitle1: {
        fontSize: '1.2rem',
        color: QUEST_COLORS.bgAndTitles
    },
    subtitle2: {
        fontSize: '1.2rem',
        color: QUEST_COLORS.dark
    },
    body1: {
        fontSize: '.9rem',
        color: QUEST_COLORS.dark,
        fontWeight: 'normal'
    },
    caption: {
        fontSize: '.8rem',
        color: QUEST_COLORS.dark
    }
})(Typography);


  


// h1
// h2
// h3
// h4
// h5
// h6
// subtitle1
// subtitle2
// body1
// body2
// button
// caption
// overline