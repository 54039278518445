import React from 'react';

import { Box, withStyles } from '@material-ui/core';
import { QUEST_COLORS } from '../../shared/QuestTheme';
import { MouseEventHandler } from 'react';

const QuestChip = withStyles({
  root: {
    padding: '.5rem 1rem',
    display: 'inline-flex',
    width: 'fit-content',
    marginBottom: '1rem',
    marginRight: '1rem',
    fontSize: '0.9rem',
    fontFamily: 'Poppins',
    color: 'white',
    borderRadius: 25,
    border: '1px solid white',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: QUEST_COLORS.highlights,
    },
    whiteSpace: 'normal',
  },
})(Box);

interface QuestSubjectProps {
  description: string;
  onClick: MouseEventHandler
}

const QuestSubject = ({ description, onClick }: QuestSubjectProps) => {
  return <QuestChip onClick={onClick}>{description}</QuestChip>;
};

export default QuestSubject;
