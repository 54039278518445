import React, { createContext, FC, useState } from 'react';

export interface ISearchResultReranker {
  title: string;
  score: number;
  paragraphs: Array<string>;
  highlights: Array<Array<[number, number]>>;
  raw: string;
  source_database: string;
  source_url: string;
  id: string;
  url: string;
  content_is_formated: boolean;
}

export interface ISearchResult {
  query_id: string;
  response_reranker: ISearchResultReranker[];
}

export interface IQuestSearchResultProviderProps {
  term: string;
  setTerm: (term: string) => void;
  getTerm: () => string;
  setSearchResult: (searchResult: ISearchResult) => void;
  getSearchResult: () => ISearchResult | undefined;
  openDropdownResult: boolean;
  setOpenDropdownResult: (open: boolean) => void;
  email: string;
  setEmail: (email: string) => void;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
}

const QuestSearchResultContext = createContext<IQuestSearchResultProviderProps | null>(null);

export const QuestSearchProvider: FC = ({ children }) => {
  const [term, setTerm] = useState<string>('');
  const [searchResult, setSearchResult] = useState<ISearchResult>();
  const [openDropdownResult, setOpenDropdownResult] = useState<boolean>(false);

  const getTermHandler = () => term;
  const getSearchResultHandler = () => searchResult;

  const [email, setEmail] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const providerValue: IQuestSearchResultProviderProps = {
    term,
    setTerm,
    getTerm: getTermHandler,
    setSearchResult,
    getSearchResult: getSearchResultHandler,
    openDropdownResult,
    setOpenDropdownResult,
    email,
    setEmail,
    isChecked,
    setIsChecked,
  };

  return (
    <QuestSearchResultContext.Provider value={providerValue}>
      {children}
    </QuestSearchResultContext.Provider>
  );
};

export default QuestSearchResultContext;
