import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { API_BASE, GLOBAL_TOAST_OPTIONS } from '../../../../shared/Constants';
import AuthContext, { AuthProviderProps } from '../../../../shared/context/AuthContext';
import { QuestTextLabel, QUEST_COLORS } from './QuestTheme';

const QuestRating = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [value, setValue] = useState<number | null>(0);
  const { user, getTokenAuthHeader, getResponse } = useContext(AuthContext) as AuthProviderProps;

  const getUserAnswer = () => {
    return localStorage.getItem('rating_answered');
  };

  const setUserAnswer = () => {
    localStorage.setItem('rating_answered', 'true');
  };

  const sendUserAnswer = async (rate: number | null = 0) => {
    let headers = {};

    let headerToken = {};
    if (user.token) {
      headerToken = await getTokenAuthHeader();
    }

    headers = { ...headerToken, ...{ 'Content-Type': 'application/json' } };

    const body = {
      evaluation_value: `${rate}`,
      interface: 'Quest',
    };

    const response = await getResponse(
      `${API_BASE}/interface_evaluation`,
      'POST',
      headers,
      JSON.stringify(body),
    );
    if (response.status === 200) {
      const data = await response.json();
      if (data.status === 'OK') {
        setUserAnswer();
        setOpen(false);
      }
    } else {
      toast.error(
        'Ocorreu um erro. Por favor, tente novamente em alguns minutos.',
        GLOBAL_TOAST_OPTIONS,
      );
    }
  };

  return (
    <>
      {!open || getUserAnswer() ? (
        ''
      ) : (
        <Box
          style={{
            backgroundColor: 'white',
            borderRadius: '15px',
            position: 'fixed',
            right: '15px',
            marginTop: '25%',
            padding: '25px',
            zIndex: 1000,
            width: '200px',
            textAlign: 'center',
            boxShadow: '0px 0 15px 1px #c5c5c5',
          }}
        >
          <IconButton
            style={{
              backgroundColor: QUEST_COLORS.bgAndTitles,
              borderRadius: '15px',
              color: 'white',
              padding: '2px',
              float: 'right',
              marginTop: '-10px',
              marginRight: '-10px',
            }}
            onClick={() => setOpen(false)}
          >
            <Close style={{ fontSize: '.8rem' }} />
          </IconButton>

          <QuestTextLabel style={{ color: QUEST_COLORS.bgAndTitles, fontSize: '0.8rem' }}>
            O que você está achando do <strong>Quest</strong>?
          </QuestTextLabel>
          <Rating
            name="quest-rating"
            value={value}
            precision={0.5}
            onChange={(event, newValue) => {
              setValue(newValue);
              sendUserAnswer(newValue);
            }}
          />
        </Box>
      )}
    </>
  );
};

export default QuestRating;
