import React, { createContext, FC, useState } from 'react';

export interface QuestIndex {
  name: string;
  label: string;
  visible: boolean;
  icon_url: string;
  selected: boolean;
}

export interface QuestIndexMap {
  [key: string]: QuestIndex;
}

export interface QuestIndexProviderProps {
  setIndexes: (indexes: QuestIndexMap) => void;
  getIndexes: () => QuestIndex[];
  setSelectedIndex: (index: string) => void;
  getSelectedIndex: () => string | undefined;
}

const QuestIndexContext = createContext<QuestIndexProviderProps | null>(null);

export const QuestIndexProvider: FC = ({ children }) => {
  const [indexes, setIndexes] = useState<QuestIndexMap>({});

  const indexesObj = Object.values(indexes);

  const setSelectedIndexHandler = (index: string) => {
    indexesObj.forEach((index: QuestIndex) => (index.selected = false));
    if (indexesObj.length > 0) indexes[index].selected = true;
  };

  const getSelectedIndexHandler = () =>
    indexesObj.find((x) => x.selected)?.name || indexesObj[0]?.name;

  const providerValue: QuestIndexProviderProps = {
    setIndexes,
    getIndexes: () => indexesObj,
    setSelectedIndex: setSelectedIndexHandler,
    getSelectedIndex: getSelectedIndexHandler,
  };

  return <QuestIndexContext.Provider value={providerValue}>{children}</QuestIndexContext.Provider>;
};

export default QuestIndexContext;
