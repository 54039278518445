import React, { useContext, useEffect, useState } from 'react';

import { Box, Container, Grid } from '@material-ui/core';

import 'react-multi-carousel/lib/styles.css';

import SearchImg from '../../../../img/busca.png';
import PerformanceImg from '../../../../img/resultados.png';
import RepertoryImg from '../../../../img/repertorio.png';

import QuestLogoWhite from '../../../../img/quest-w.png';
import QuestNormalLogo from '../../../../img/quest.png';
import QuestCardFeature from './QuestCardFeature/QuestCardFeature';

import WebFont from 'webfontloader';
import QuestSearch from '../shared/QuestSearch/QuestSearch';
import { QuestTextLabel, QUEST_COLORS } from '../shared/QuestTheme';
import QuestSubject from './QuestSubject/QuestSuject';

import QuestAboutAccordion from './QuestAboutAccordion/QuestAboutAccordion';
import styles from './QuestHome.module.css';
import { BrowserView, MobileView } from 'react-device-detect';
import QuestFooter from '../shared/QuestFooter';
import AuthContext, { AuthProviderProps } from '../../../../shared/context/AuthContext';
import { API_BASE, GLOBAL_TOAST_OPTIONS, QUEST_DEFAULT_INDEX } from '../../../../shared/Constants';
import { toast } from 'react-toastify';
import QuestSearchResultContext, {
  IQuestSearchResultProviderProps,
} from '../QuestSearchResultContext';
import { Helmet } from 'react-helmet';
import QuestRating from '../shared/QuestRating';
import MaintenanceSnackBar from '../../../common/MaintenanceSnackBar';

const QuestHome = () => {
  const { user, getTokenAuthHeader, getResponse } = useContext(AuthContext) as AuthProviderProps;
  const [popularQueries, setPopularQueries] = useState([]);
  const [showMaintenanceSnackBar, setShowMaintenanceSnackBar] = useState<boolean>(
    process.env.REACT_APP_SCHEDULED_MAINTENANCE === 'true',
  );

  const { setTerm } = useContext(QuestSearchResultContext) as IQuestSearchResultProviderProps;

  useEffect(() => {
    if (
      navigator.geolocation &&
      !localStorage.getItem('latitude') &&
      !localStorage.getItem('longitude')
    ) {
      navigator.geolocation.getCurrentPosition(function (position) {
        localStorage.setItem('latitude', position.coords.latitude.toString());
        localStorage.setItem('longitude', position.coords.longitude.toString());
      });
    }
  }, []);

  useEffect(() => {
    const loadPopularQueries = async () => {
      let headers = {};

      if (user.token) {
        headers = await getTokenAuthHeader();
      }

      const response = await getResponse(`${API_BASE}/popular_queries`, 'GET', headers);

      if (response.status === 200) {
        const data = await response.json();
        setPopularQueries(data['queries']);
      } else {
        toast.error(
          'Ocorreu um erro. Por favor, tente novamente em alguns minutos.',
          GLOBAL_TOAST_OPTIONS,
        );
      }
    };

    loadPopularQueries();
  }, [getResponse, getTokenAuthHeader, user.token]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins'],
      },
    });
  }, []);

  const handlerPopularQueries = (query: string) => {
    setTerm(query);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      document.getElementById('quest-search-bar')?.focus();
    }, 1000);
  };

  return (
    <>
      <BrowserView>
        <QuestRating />
      </BrowserView>
      <Helmet>
        <title>Quest</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12}>
          <Box className={styles.homePage}>
            <Container maxWidth="md">
              <Box textAlign="center">
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <img src={QuestNormalLogo} className={styles.logo} alt="Logo do Quest" />
                    <QuestTextLabel key="tireSuasDuvidasTecnicas" variant="h1">
                      Tire suas dúvidas técnicas ou empresariais
                    </QuestTextLabel>
                    <QuestSearch indexSelected={QUEST_DEFAULT_INDEX} />
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignContent="center">
        <Box textAlign="center" width="100%" bgcolor={QUEST_COLORS.bgAndTitles}>
          <Box marginTop="-20vh" width="100%">
            <QuestTextLabel key="aRepostaCerta" variant="subtitle2" style={{ marginBottom: '3vh' }}>
              A resposta certa para o seu negócio
            </QuestTextLabel>
            <BrowserView>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="stretch"
                alignContent="center"
              >
                <QuestCardFeature
                  key="comoAjudamosEmpreendedores"
                  icon={SearchImg}
                  title="Como ajudamos empreendedores"
                  description="Quer abrir um negócio? Tem alguma dúvida técnica ou de gestão do seu negócio? Pode
                    perguntar ao Quest! Ele vai procurar dentro das bases do SEBRAE para, em segundos, te dar
                    uma resposta assertiva e confiável. Respondemos desde dúvidas sobre gestão de negócios até
                    questões fiscais, legais e tributárias."
                />

                <QuestCardFeature
                  key="oQueaNossaBuscaTemDeDiferente"
                  icon={RepertoryImg}
                  title="O que a nossa busca tem de diferente"
                  description="Além de trazer respostas rápidas e mais relevantes, nosso sistema foi integrado à base de
                    conhecimento do SEBRAE o que garante informações de fonte confiável. Assim, você encontra
                    informações confiáveis para que o seu negócio cresça."
                />

                <QuestCardFeature
                  key="repertorio"
                  icon={PerformanceImg}
                  title="O que é o Quest?"
                  description="É o mecanismo de busca parceiro do empreendedor, criado pela NeuralMind com fomento do
                  SEBRAE. Ele utiliza inteligência artificial para encontrar as melhores respostas dentro de toda
                  base de conhecimento construída pelo SEBRAE, respondendo dúvidas que os
                  empreendedores se deparam todos os dias na criação ou gestão de seus negócios."
                />
              </Grid>
            </BrowserView>
            <MobileView>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                direction="row"
                // alignContent="center"
              >
                <Grid item xs={12}>
                  <QuestCardFeature
                    key="comoAjudamosEmpreendedoresMob"
                    icon={SearchImg}
                    title="Como ajudamos empreendedores"
                    description="Quer abrir um negócio? Tem alguma dúvida técnica ou de gestão do seu negócio? Pode
                    perguntar ao Quest! Ele vai procurar dentro das bases do SEBRAE para, em segundos, te dar
                    uma resposta assertiva e confiável. Respondemos desde dúvidas sobre gestão de negócios até
                    questões fiscais, legais e tributárias."
                  />
                </Grid>
                <Grid item xs={12}>
                  <QuestCardFeature
                    key="oQueaNossaBuscaTemDeDiferenteMob"
                    icon={PerformanceImg}
                    title="O que a nossa busca tem de diferente"
                    description="Além de trazer respostas rápidas e mais relevantes, nosso sistema foi integrado à base de
                    conhecimento do SEBRAE o que garante informações de fonte confiável. Assim, você encontra
                    informações confiáveis para que o seu negócio cresça."
                  />
                </Grid>
                <Grid item xs={12}>
                  <QuestCardFeature
                    key="repertorioMob"
                    icon={RepertoryImg}
                    title="O que é o Quest?"
                    description="É o mecanismo de busca parceiro do empreendedor, criado pela NeuralMind com fomento do
                    SEBRAE. Ele utiliza inteligência artificial para encontrar as melhores respostas dentro de toda
                    base de conhecimento construída pelo SEBRAE, respondendo dúvidas que os
                    empreendedores se deparam todos os dias na criação ou gestão de seus negócios."
                  />
                </Grid>
              </Grid>
            </MobileView>
          </Box>
        </Box>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box
            paddingTop="10vh"
            paddingBottom="20vh"
            textAlign="center"
            bgcolor={QUEST_COLORS.bgAndTitles}
          >
            <Container maxWidth="lg">
              <QuestTextLabel
                key="assuntosMaisProcurados"
                variant="h6"
                className={styles.subtitleWhite}
              >
                Veja os assuntos mais procurados no Quest por empreendedores
              </QuestTextLabel>
              <Box marginTop="2vh">
                {popularQueries.map((query: string) => (
                  <QuestSubject
                    key={query}
                    description={query}
                    onClick={() => handlerPopularQueries(query)}
                  />
                ))}
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box
            textAlign="center"
            className={styles.aboutSection}
            bgcolor={QUEST_COLORS.bgAndTitles}
          >
            <Container maxWidth="lg">
              <QuestTextLabel variant="h6" className={styles.subtitleWhite}>
                Saiba mais sobre o Quest
              </QuestTextLabel>
              <Box marginTop="2vh">
                <QuestAboutAccordion
                  key="tecnologiaEnvolvidaQuest"
                  title="A tecnologia envolvida no Quest"
                  description="
                  O Quest utiliza o NeuralSearchX para trazer as melhores respostas para dúvidas empresariais.
                  O NeuralSearchX é um buscador, desenvolvido pela NeuralMind que utiliza o estado da arte
                  em inteligência artificial e processamento de linguagem natural para processar documentos e
                  utilizá-los para responder perguntas feitas pelo usuário."
                />

                <QuestAboutAccordion
                  key="comoFiltrar"
                  title="Saiba como filtrar a sua pesquisa no Quest"
                  description="
                  No canto esquerdo da barra de busca, você encontra as opções SEBRAE ou Web. A opção SEBRAE garante que as respostas para as suas perguntas
                  sejam retiradas apenas da base do SEBRAE  ou do SBRT (Serviço Brasileiro de Respostas Técnicas), enquanto a opção Web engloba informações
                  de outras páginas da internet. Cabe a você escolher a melhor opção para a sua busca."
                />
              </Box>
              <Box className={styles.aboutSectionFooter}>
                <img src={QuestLogoWhite} width="150vw" alt="Logo do Quest" />
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>
      <MaintenanceSnackBar
        language="pt"
        showSnackBar={showMaintenanceSnackBar}
        setShowSnackBar={setShowMaintenanceSnackBar}
      />
      <QuestFooter />
    </>
  );
};

export default QuestHome;
