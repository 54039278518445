import { FormControl, Grid, InputBase, MenuItem, Select, styled } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { QUEST_COLORS, QUEST_FONT_FAMILY } from '../QuestTheme';
import AuthContext, { AuthProviderProps } from '../../../../../shared/context/AuthContext';
import QuestContext, { QuestIndex, QuestIndexProviderProps } from '../../QuestIndexContext';
import {
  API_BASE,
  GLOBAL_TOAST_OPTIONS,
  QUEST_RESULT_ROUTE,
  SEARCH_ENDPOINT,
} from '../../../../../shared/Constants';
import { toast } from 'react-toastify';

import styles from './QuestSearchType.module.css';
import QuestSearchResultContext, {
  IQuestSearchResultProviderProps,
} from '../../QuestSearchResultContext';
import { useHistory } from 'react-router';

export interface IIndexSelected {
  indexSelected: string;
}

const QuestSearchType = ({ indexSelected }: IIndexSelected) => {
  const history = useHistory();
  const { user, getTokenAuthHeader, getResponse } = useContext(AuthContext) as AuthProviderProps;

  const { setIndexes, getIndexes, setSelectedIndex, getSelectedIndex } = useContext(
    QuestContext,
  ) as QuestIndexProviderProps;

  const { getTerm } = useContext(QuestSearchResultContext) as IQuestSearchResultProviderProps;

  useEffect(() => {
    setSelectedIndex(indexSelected);
  }, [indexSelected, setSelectedIndex]);

  useEffect(() => {
    const fetchData = async () => {
      let headers = {};

      if (user.token) {
        headers = await getTokenAuthHeader();
      }

      const response = await getResponse(
        `${API_BASE}${SEARCH_ENDPOINT}/datasets?target_interface=quest`,
        'GET',
        headers,
      );

      if (response.status === 200) {
        const { indices } = await response.json();
        setIndexes(indices);
      } else {
        toast.error(
          'Ocorreu um erro. Por favor, tente novamente em alguns minutos.',
          GLOBAL_TOAST_OPTIONS,
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeChangedHandler = (event: any) => {
    setSelectedIndex(event.target.value as string);
    if (getTerm())
      history.push(
        `${QUEST_RESULT_ROUTE}?query=${encodeURIComponent(getTerm())}&index=${encodeURIComponent(
          getSelectedIndex() || '',
        )}`,
      );
  };

  return (
    <FormControl className={styles.dropdownType} fullWidth>
      {getIndexes().length > 0 ? (
        <Select
          variant="outlined"
          input={<BootstrapInput />}
          onChange={typeChangedHandler}
          defaultValue={indexSelected}
        >
          {getIndexes().map((index: QuestIndex) => {
            return (
              <MenuItem value={index.name} key={index.name}>
                <Grid container justifyContent="center" direction="row">
                  <Grid item>
                    <img
                      src={index.icon_url}
                      alt="Logo SEBRAE"
                      style={{ maxWidth: 60, marginTop: 4 }}
                    />
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        ''
      )}
    </FormControl>
  );
};

const BootstrapInput = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    position: 'relative',
    border: `1px solid ${QUEST_COLORS.borders}`,
    fontSize: '.9rem',
    padding: '0 50px',
    fontFamily: QUEST_FONT_FAMILY,
    color: QUEST_COLORS.dark,
    height: 45,
    '&:focus': {
      borderRadius: 4,
      borderColor: QUEST_COLORS.borders,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '@media (max-width: 420px)': {
      borderTopRightRadius: 7,
      borderBottomRightRadius: 7,
      border: '1px solid #afafaf',
    },
  },
}));

export default QuestSearchType;
