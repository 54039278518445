import { Box, Container, Grid } from "@material-ui/core";
import React from "react";
import { QuestLink } from "../QuestHome/QuestLink";
import { QuestTextLabel } from "./QuestTheme";

import NeuralMindAiLogo from '../../../../img/neuralmind-ai-logo.png';
import MarcaSebrae from '../../../../img/logo_sebrae.png';



const QuestFooter = () => {
    return (
        <Container
        maxWidth="lg"
        style={{
          paddingTop: '20px',
          paddingBottom: '10px',
          verticalAlign: 'center',
          textAlign: 'center',
        }}
      >
        <Grid container justifyContent="center" alignContent="center" alignItems="center">
          <Grid item xs md>
            <QuestLink href="https://neuralmind.ai/" target="_blank">
              <QuestTextLabel>
                <img src={NeuralMindAiLogo} style={{ width: 175 }} alt="Logo da NeuralMind" />
              </QuestTextLabel>
            </QuestLink>
          </Grid>
          <Grid container item md={8} xs={12}>
            <Grid item md xs={12}>
              <QuestLink href="https://docs.google.com/document/d/1YYJV2Mb5JLwJYZEL3X8GI4ZylTedpEy6w7SxRcu6S_M/edit?usp=sharing" target="_blank">
                <QuestTextLabel>Termos e condições</QuestTextLabel>
              </QuestLink>
            </Grid>
            <Grid item md xs={12}>
              <QuestLink href="https://neuralmind.ai/politica-de-cookies/" target="_blank">
                <QuestTextLabel>Cookies</QuestTextLabel>
              </QuestLink>
            </Grid>
            <Grid item md xs={12}>
              <QuestLink href="https://neuralmind.ai/politica-de-privacidade/" target="_blank">
                <QuestTextLabel>Privacidade</QuestTextLabel>
              </QuestLink>
            </Grid>
            <Grid item md xs={12}>
              <QuestLink href="https://neuralmind.ai/sobre-a-neuralmind/" target="_blank">
                <QuestTextLabel>Quem somos</QuestTextLabel>
              </QuestLink>
            </Grid>
            <Grid item md xs={12}>
              <QuestLink href="https://nsx.ai/quest/" target="_blank">
                <QuestTextLabel>Projeto Quest</QuestTextLabel>
              </QuestLink>
            </Grid>
          </Grid>
          <Grid item md xs={12}>
            <Box sx={{ border: '1px solid #ccc', padding: 5 }}>
              <QuestTextLabel>Projeto fomentado pelo </QuestTextLabel>
              <img src={MarcaSebrae} style={{ width: 75 }} alt="Logo da NeuralMind" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
};

export default QuestFooter;