import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import NotFoundPage from './components/pages/NotFoundPage';
import { AuthProvider } from './shared/context/AuthContext';
import { InterfaceParametersProvider } from './shared/context/InterfaceParametersContext';
import { SearchBarProvider } from './shared/context/SearchBarContext';
import { HOME_ROUTE, PAGE_DESCRIPTION, PAGE_FAVICON, QUEST_RESULT_ROUTE } from './shared/Constants';
import Theme from './shared/Theme';
import { QuestSearchProvider } from './components/pages/Quest/QuestSearchResultContext';
import { QuestIndexProvider } from './components/pages/Quest/QuestIndexContext';
import QuestHome from './components/pages/Quest/QuestHome/QuestHome';
import QuestSearchResult from './components/pages/Quest/QuestSearchResult/QuestSearchResult';

const AppQuest = () => {
  return (
    <>
      <ThemeProvider theme={Theme}>
        {/* Dynamically load metadata for HTML header */}
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={PAGE_DESCRIPTION} />
          <link rel="icon" href={PAGE_FAVICON} />
          <style>{`body { background-color: ${Theme.backgroundSoftWhite}; }`}</style>
        </Helmet>
        <Router>
          <AppContainer>
            <AuthProvider>
              <InterfaceParametersProvider>
                <SearchBarProvider>
                  <QuestSearchProvider>
                    <QuestIndexProvider>
                      <Switch>
                        <Route exact path={HOME_ROUTE}>
                          <QuestHome />
                        </Route>
                        <Route exact path={QUEST_RESULT_ROUTE}>
                          <QuestSearchResult homeQuestUrl={HOME_ROUTE} />
                        </Route>
                        <Route>
                          <NotFoundPage />
                        </Route>
                      </Switch>
                    </QuestIndexProvider>
                  </QuestSearchProvider>
                </SearchBarProvider>
              </InterfaceParametersProvider>
            </AuthProvider>
          </AppContainer>
        </Router>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default AppQuest;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
